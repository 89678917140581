import React, {forwardRef} from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TableContainer = forwardRef(({ allData, data, totalData }, ref) => {
  const { t } = useTranslation();
  return (
    <Table striped bordered responsive hover size="sm"
           className="modal-table">
      <thead>
        <tr>
          <th className="column-person">{t("person")}</th>
          <th>{t("order")}</th>
          <th>{t("public")}</th>
          <th>{data?.part}%</th>
          <th>{t("account")}</th>
        </tr>
      </thead>
      <tbody>
        {allData.map((i, idx) => (
          <tr key={idx}>
            <th className="column-person">{i.name}</th>
            <td>{i.sum}</td>
            <td>{i.other}</td>
            <td> {i.part}</td>
            <th>{i.total}</th>
          </tr>
        ))}
      </tbody>
      {allData.length > 0 && (
        <tfoot>
          <tr>
            <th>{totalData?.name}</th>
            <th>{totalData?.sum}</th>
            <th>{totalData?.other}</th>
            <th> {totalData?.part}</th>
            <th>{totalData?.total}</th>
          </tr>
        </tfoot>
      )}
    </Table>
  );
});
export default TableContainer;
