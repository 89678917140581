import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

function FormContainer({
  handleSubmit,
  data,
  persons,
  handleChange,
  handleAddPerson,
  handleRemoveItem,
  handleChangeSum,
}) {
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label className="mb-0">{t("common")}</Form.Label>
        <InputGroup>
          <Form.Control
            required
            type="text"
            name="total"
            placeholder={t("common")}
            value={data.total}
            onChange={handleChange}
          />
          <InputGroup.Text>{t("sum")}</InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="mb-0">{t("percent")}</Form.Label>
        <InputGroup>
          <Form.Control
            required
            type="text"
            name="part"
            placeholder={t("percent")}
            value={data.part}
            onChange={handleChange}
          />
          <InputGroup.Text>%</InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <div className="d-flex justify-content-end">
        <Button type="button" onClick={handleAddPerson} variant="success" className="w-50 mb-3">
          <i className="bi bi-person-fill-add"></i> {t("add")}
        </Button>
      </div>
      {persons.length > 0 ? (
        <div className="all">
          {persons.map((i, idx) => (
            <div key={idx}>
              <InputGroup className="mb-2">
                <Form.Control
                  required
                  type="text"
                  name="name"
                  value={i.name}
                  onChange={(e) => handleChangeSum(e, idx)}
                />
                <Form.Control required type="text" name="sum" value={i.sum} onChange={(e) => handleChangeSum(e, idx)} />
                <Button variant="danger" type="button" onClick={() => handleRemoveItem(i.id)}>
                  <i className="bi bi-trash3-fill"></i>
                </Button>
              </InputGroup>
            </div>
          ))}
        </div>
      ) : null}
      {persons.length > 0 ? (
        <Button type="submit" className="w-100 py-2 my-3">
          <i className="bi bi-cash"></i> {t("count")}
        </Button>
      ) : null}
    </Form>
  );
}

export default FormContainer;
