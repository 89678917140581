import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import FormContainer from "./components/Form";
import ModalWrapper from "./components/Modal";
import Table from "./components/Table";
import { formatMoney } from "./utils/formatMoney";
import { useTranslation } from "react-i18next";
import { useScreenshot } from "use-react-screenshot";
import { shareOnMobile } from "react-mobile-share";

function App() {
  const { t } = useTranslation();
  const [data, setData] = useState({ total: "", part: "" });
  const [persons, setPersons] = useState([]);
  const [allData, setAllData] = useState([]);
  const [totalData, setTotalData] = useState({});
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShare = async () => {
    setIsLoading(true);
    await takeScreenshot(ref.current)
      .then(() => {
        setIsLoading(false);
        shareOnMobile({
          text: "Share this bill with your friends",
          url: window.location.href,
          title: "Split Bill",
          images: [image],
        });
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setData((prev) => {
      if (name === "total") return { ...prev, total: formatMoney(value) };
      else return { ...prev, part: Number(value) };
    });
  };

  const handleAddPerson = () => {
    setPersons((prev) => [...prev, { name: `${t("person")}-${prev.length + 1}`, sum: "", id: uuidv4() }]);
  };
  const handleRemoveItem = (id) => {
    setPersons((prev) => prev.filter((i) => i.id !== id));
  };

  const handleChangeSum = (e, idx) => {
    const { name, value } = e.target;
    setPersons((prev) => {
      let findData = prev[idx];
      if (name === "name") return prev.map((item, i) => (i === idx ? { ...findData, name: value } : item));
      else return prev.map((item, i) => (i === idx ? { ...findData, sum: formatMoney(value) } : item));
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleShow();

    const parse = (s) => Number(String(s).split(" ").join(""));

    const total = parse(data.total);

    // bracha yeganlarni summasi foizsiz
    const allSum = persons.map((item) => parse(item.sum)).reduce((p, a) => p + a, 0);

    // Foizda qolgan summa
    const allX = Math.round((total * 100) / (100 + data.part));

    //Foiz
    const allPart = total - allX;
    // O'tirib yeyishga ketgan rasxot
    const allOther = allX - allSum;
    const onePart = Math.round(allOther / persons.length);
    const newArr = persons.map(({ name, sum }) => {
      const amount = parse(sum);
      const withoutPercent = amount + onePart;
      const percentSum = Math.round((withoutPercent * data.part) / 100);
      const total = withoutPercent + percentSum;
      return {
        name,
        sum: formatMoney(String(amount)),
        other: formatMoney(String(onePart)),
        part: formatMoney(String(percentSum)),
        total: formatMoney(String(total)),
      };
    });

    setAllData(newArr);
    setTotalData({
      name: t("total"),
      sum: formatMoney(String(allSum)),
      other: formatMoney(String(allOther)),
      part: formatMoney(String(allPart)),
      total: formatMoney(String(newArr.map((i) => parse(i.total)).reduce((p, a) => p + a, 0))),
    });
  };
  return (
    <>
      <Header />
      <Container className="mt-4">
        <FormContainer
          handleSubmit={handleSubmit}
          data={data}
          persons={persons}
          handleChange={handleChange}
          handleAddPerson={handleAddPerson}
          handleRemoveItem={handleRemoveItem}
          handleChangeSum={handleChangeSum}
        />
        <ModalWrapper isLoading={isLoading} ref={ref} show={show} handleClose={handleClose} handleShare={handleShare}>
          <Table allData={allData} data={data} totalData={totalData} />
        </ModalWrapper>
      </Container>
    </>
  );
}

export default App;
