import React, { forwardRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";

const ModalWrapper = forwardRef(({ children, show, handleClose, handleShare, isLoading }, ref) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div ref={ref}>
        <Modal.Header closeButton>
          <Modal.Title>{t("amount")}</Modal.Title>
          <b className="px-4">{new Date().toJSON().slice(0, 10).replace(/-/g, ".")}</b>
        </Modal.Header>
        <Modal.Body className="py-0 px-1">{children}</Modal.Body>
      </div>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          <i className="bi bi-x"></i> {t("close")}
        </Button>
        <Button variant="primary" onClick={handleShare}>
          <i className="bi bi-share-fill"></i> {t("share")} {isLoading && <Spinner animation="border" size="sm" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default ModalWrapper;
