import React from "react";
import { Container } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

function Header() {
  const options = [
    { value: "kr_uz", label: "O'zbek(kril)" },
    { value: "ln_uz", label: "O'zbek(lotin)" },
    { value: "en", label: "Inglizcha" },
    { value: "ru", label: "Ruscha" },
  ];

  const { i18n } = useTranslation();

  const handleChange = (e) => {
    i18n.changeLanguage(e.target.value);
    console.log(e.target.value);
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">Oson Oshxona</Navbar.Brand>{" "}
        <div>
          <Form.Select onChange={handleChange}>
            {options.map((i) => (
              <option key={i.value} value={i.value}>
                {i.label}
              </option>
            ))}
          </Form.Select>
        </div>
      </Container>
    </Navbar>
  );
}

export default Header;
