// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      total: "Total",
      common: "Total amount",
      percent: "Percent",
      add: "Add a person",
      count: "Calculation",
      person: "Person",
      public: "General",
      order: "Order",
      account: "Account",
      sum: "soum",
      amount: "Calculated amount",
      close: "Cancel",
      share: "Share",
    },
  },
  ln_uz: {
    translation: {
      total: "Jami",
      common: "Umumiy summa",
      percent: "Foiz",
      add: "Shaxs qo'shish",
      count: "Hisoblash",
      person: "Shaxs",
      public: "Umumiy",
      order: "Buyurtma",
      account: "Hisob",
      sum: "so'm",
      amount: "Hisoblangan summa",
      close: "Bekor qilish",
      share: "Yuborish",
    },
  },
  kr_uz: {
    translation: {
      total: "Жами",
      common: "Умумий сумма",
      percent: "Фоиз",
      add: "Шахс қўшиш",
      count: "Ҳисоблаш",
      person: "Шахс",
      public: "Умумий",
      order: "Буюртма",
      account: "Ҳисоб",
      sum: "сўм",
      amount: "Ҳисобланган сумма",
      close: "Бекор қилиш",
      share: "Юбориш",
    },
  },
  ru: {
    translation: {
      total: "Итого",
      common: "Общая сумма",
      percent: "Процент",
      add: "Добавить человека",
      count: "Считать",
      person: "Человек",
      public: "Общедоступный",
      order: "Заказать",
      account: "Учетная запись",
      sum: "сумма",
      amount: "Расчетная сумма",
      close: "Отменить",
      share: "Делиться",
    },
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "kr_uz",
    fallbackLng: "kr_uz",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
